<template>
  <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.6001 1.59961C4.93147 1.59961 5.2001 1.86824 5.2001 2.19961V3.19961H10.8001V2.19961C10.8001 1.86824 11.0687 1.59961 11.4001 1.59961C11.7315 1.59961 12.0001 1.86824 12.0001 2.19961V3.19961H12.2001C13.4151 3.19961 14.4001 4.18458 14.4001 5.39961V12.1996C14.4001 13.4146 13.4151 14.3996 12.2001 14.3996H3.8001C2.58507 14.3996 1.6001 13.4146 1.6001 12.1996V5.39961C1.6001 4.18458 2.58507 3.19961 3.8001 3.19961H4.0001V2.19961C4.0001 1.86824 4.26873 1.59961 4.6001 1.59961ZM3.8001 5.99961C3.24781 5.99961 2.8001 6.44732 2.8001 6.99961V12.1996C2.8001 12.7519 3.24781 13.1996 3.8001 13.1996H12.2001C12.7524 13.1996 13.2001 12.7519 13.2001 12.1996V6.99961C13.2001 6.44732 12.7524 5.99961 12.2001 5.99961H3.8001Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
